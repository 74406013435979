"use client";

import React, { useEffect, useState } from "react";
import Image from "next/legacy/image";

import Link from "next/link";
import { CircleUser, Menu, Package2, Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "./ui/input";
import { createClient } from "@/utils/supabase/client";
import { User } from "@/app/protected/dashboard/page";
import {
  getProfileAction,
  getUser,
  navigate,
  signOutAction,
} from "@/app/auth-actions";
import { Profile } from "@/app/protected/dashboard/components/settings";
import { searchMap } from "@/lib/definitions";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "./ui/command";
import { Skeleton } from "./ui/skeleton";

interface HeaderProps {
  buttons?: React.ReactNode[];
  authButtons?: React.ReactNode[];
}

const Header: React.FC<HeaderProps> = ({
  buttons: buttons,
  authButtons: authButtons,
}) => {
  // return (

  //   <>
  //     <header className="sticky top-0 flex h-16 items-center border-b bg-background px-4 md:px-6 z-10 w-full overflow-hidden">
  //       <nav className="flex w-full items-center justify-between text-lg font-medium">
  //         <Link
  //           href="/"
  //           className="flex items-center gap-2 text-lg font-semibold md:text-base"
  //         >
  //           <div className="relative min-h-[50px] min-w-[50px] hidden sm:block md:flex ">
  //             <Image
  //               alt="logo"
  //               src="/https://utfs.io/f/HKemhjN71TyOYi7mVQjSRsUuM1q6PnFzKcEJpfd4b5O8I0Zi"
  //               layout="fill"
  //               className="rounded-lg"
  //             />
  //           </div>
  //           <span className="sr-only">rustymm</span>
  //         </Link>
  //         <div className="items-center space-x-8 whitespace-nowrap mx-6 hidden sm:block md:flex">
  //           {buttons?.map((button, index) => (
  //             <React.Fragment key={index}>{button}</React.Fragment>
  //           ))}
  //         </div>
  //       </nav>
  //       <Sheet>
  //         <SheetTrigger asChild>
  //           <Button
  //             variant="outline"
  //             size="icon"
  //             className="shrink-0 md:hidden"
  //           >
  //             <Menu className="h-5 w-5" />
  //             <span className="sr-only">Toggle navigation menu</span>
  //           </Button>
  //         </SheetTrigger>
  //         <SheetContent side="left">
  //           <nav className="grid gap-6 text-lg font-medium">
  //             <Link
  //               href="/"
  //               className="flex items-center gap-2 text-lg font-semibold md:text-base"
  //             >
  //               <Image
  //                 alt="logo"
  //                 src="/https://utfs.io/f/HKemhjN71TyOYi7mVQjSRsUuM1q6PnFzKcEJpfd4b5O8I0Zi"
  //                 className="rounded-lg"
  //                 width={60}
  //                 height={48}
  //                 loading="lazy"
  //                 decoding="async"
  //                 style={{ color: "transparent" }}
  //               />
  //               <span className="sr-only">rustymm</span>
  //             </Link>
  //             <Link
  //               href="/"
  //               className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
  //             >
  //               Home
  //             </Link>
  //             <Link
  //               href="/signup"
  //               className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
  //             >
  //               Get Started
  //             </Link>
  //             <Link
  //               href="/terms-of-service"
  //               className="text-muted-foreground transition-colors hover:text-foreground min-w-fit"
  //             >
  //               Terms Of Service
  //             </Link>
  //           </nav>
  //         </SheetContent>
  //       </Sheet>
  //     </header>
  //   </>
  // );

  const [userData, setUserData] = useState<User>();
  const [profilePicture, setProfilePicture] = useState<string | null>();
  const [username, setUsername] = useState<string>("");
  const [showContent, setShowContent] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleSelect = (value: string) => {
    navigate(value);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getUser();

        if (user) {
          setUserData(user as unknown as User);
          return true;
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        return false;
      }
    };

    const fetchProfile = async () => {
      if (!userData) return;

      try {
        const response = await getProfileAction();

        if (response && response.profile) {
          const profileData: Profile = response.profile;
          setUsername(profileData.username);
          setProfilePicture(
            profileData.profile_image_url || "/placeholder.svg"
          );

          setShowContent(true);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const initFetch = async () => {
      if (process.env.NODE_ENV === "production") {
        const userFetched = await fetchUser();
        if (userFetched) {
          fetchProfile();
        }
      }
    };

    initFetch();
  }, [userData]);

  return (
    <>
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-10">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link
            href="/"
            className="flex items-center gap-2 text-lg font-semibold md:text-base"
          >
            <div className="relative min-h-[50px] min-w-[50px] hidden sm:block md:flex ">
              <Image
                alt="logo"
                src="https://utfs.io/f/HKemhjN71TyOYi7mVQjSRsUuM1q6PnFzKcEJpfd4b5O8I0Zi"
                layout="fill"
                className="rounded-lg"
              />
            </div>
            <span className="sr-only">rustymm</span>
          </Link>
          {buttons?.map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link
                href="/"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <Image
                  alt="logo"
                  src="https://utfs.io/f/HKemhjN71TyOYi7mVQjSRsUuM1q6PnFzKcEJpfd4b5O8I0Zi"
                  className="rounded-lg"
                  width={60}
                  height={48}
                  loading="lazy"
                  decoding="async"
                  style={{ color: "transparent" }}
                />
                <span className="sr-only">rustymm</span>
              </Link>
              {buttons?.map((button, index) => (
                <React.Fragment key={index}>{button}</React.Fragment>
              ))}
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(!open)}
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] w-full bg-transparent text-muted-foreground text-left align-middle justify-start"
              >
                {" "}
                Quick Search{" "}
              </Button>
            </div>
          </form>
          {userData && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="secondary"
                  size="icon"
                  className="rounded-full"
                >
                  {showContent &&
                    (profilePicture ? (
                      <img
                        alt="profile_picture"
                        className="h-9 w-9 rounded-full"
                        src={profilePicture}
                        width={256}
                        height={256}
                      />
                    ) : (
                      <Skeleton className="h-5 w-5 rounded-full" />
                    ))}
                  <span className="sr-only">Toggle user menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {authButtons?.map((button, index) => (
                  <React.Fragment key={index}>{button}</React.Fragment>
                ))}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    return signOutAction();
                  }}
                >
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </header>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Tell us what you are looking for" />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Suggestions">
            {Object.entries(searchMap)
              .filter(([key, value]) => {
                return true;
              })
              .map(([key, value]) => (
                <CommandItem
                  key={key}
                  onSelect={() => handleSelect(value.value)}
                >
                  <span>{value.label}</span>
                  <CommandShortcut>
                    {"shortcut" in value
                      ? (value.shortcut as React.ReactNode)
                      : (value.value as React.ReactNode)}
                  </CommandShortcut>
                </CommandItem>
              ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
};

export default Header;
