"use client";

import React from "react";
import { motion } from "framer-motion";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { CalendarIcon } from "lucide-react";

const Footer: React.FC = () => {
  return (
    <motion.footer
      className="w-full py-6 md:px-8 md:py-8 bg-background"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 md:flex-row ">
        <div className="flex flex-col items-center md:items-start">
          <p className="text-center text-sm leading-loose md:text-left">
            Built by{" "}
            <HoverCard>
              <HoverCardTrigger className="font-medium underline underline-offset-4">
                rustyspotted
              </HoverCardTrigger>
              <HoverCardContent className="w-80 mt-8">
                <div className="flex justify-between space-x-4">
                  <Avatar>
                    <AvatarImage src="/https://utfs.io/f/HKemhjN71TyOJ31LN2pOrBCJZjcAhPYGavwW4Df5g3uMKERt" />
                    <AvatarFallback>VC</AvatarFallback>
                  </Avatar>
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold">@rustyspottedcatt</h4>
                    <p className="text-sm">
                      Main Developer to the rustymiddleman project.
                    </p>
                    <div className="flex items-center pt-2">
                      <CalendarIcon className="mr-2 h-4 w-4 opacity-70" />{" "}
                      <span className="text-xs text-muted-foreground">
                        Working since 2024-09-01
                      </span>
                    </div>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
            . Copyright © 2024{" "}
          </p>
        </div>
        <div className="flex flex-col items-center md:items-end">
          <p className="text-center text-sm">
            <a href="/privacy-policy" className="underline underline-offset-4">
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="/terms-of-service"
              className="underline underline-offset-4"
            >
              Terms of Service
            </a>
          </p>
          <p className="text-center text-xs mt-2">
            Follow us on{" "}
            <a
              href="https://twitter.com/zzen_a"
              target="_blank"
              rel="noreferrer"
              className="underline underline-offset-4"
            >
              Twitter
            </a>{" "}
            and{" "}
            <a
              href="https://github.com/rustyspottedcatt"
              target="_blank"
              rel="noreferrer"
              className="underline underline-offset-4"
            >
              GitHub
            </a>
            .
          </p>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
