export const currencyMap = {
  eth: "ETH",
  btc: "BTC",
  ltc: "LTC",
  usdt: "USDT",
  usdc: "USDC",
  avax: "AVAX",
  bch: "BCH",
  bnb: "BNB",
  busd: "BUSD",
  dai: "DAI",
  sol: "SOL",
  trx: "TRX",
  dash: "DASH",
  doge: "DOGE",
  shib: "SHIB",
  matic: "MATIC",
  ton: "TON",
  verse: "VERSE",
  xmr: "XMR",
};

export const emojiMap = {
  eth: process.env.MM_ETH_EMOJI_ID,
  btc: process.env.MM_BTC_EMOJI_ID,
  ltc: process.env.MM_LTC_EMOJI_ID,
  usdt: process.env.MM_USDT_EMOJI_ID,
  usdc: process.env.MM_USDC_EMOJI_ID,
  avax: process.env.MM_AVAX_EMOJI_ID,
  bch: process.env.MM_BCH_EMOJI_ID,
  bnb: process.env.MM_BNB_EMOJI_ID,
  busd: process.env.MM_BUSD_EMOJI_ID,
  dai: process.env.MM_DAI_EMOJI_ID,
  sol: process.env.MM_SOL_EMOJI_ID,
  trx: process.env.MM_TRX_EMOJI_ID,
  dash: process.env.MM_DASH_EMOJI_ID,
  doge: process.env.MM_DOGE_EMOJI_ID,
  shib: process.env.MM_SHIB_EMOJI_ID,
  matic: process.env.MM_MATIC_EMOJI_ID,
  ton: process.env.MM_TON_EMOJI_ID,
  verse: process.env.MM_VERSE_EMOJI_ID,
  xmr: process.env.MM_XMR_EMOJI_ID,
};

export const imageMap = {
  eth: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
  btc: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
  ltc: "https://cryptologos.cc/logos/litecoin-ltc-logo.png",
  usdt: "https://cryptologos.cc/logos/tether-usdt-logo.png",
  usdc: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
  avax: "https://cryptologos.cc/logos/avalanche-avax-logo.png",
  bch: "https://cryptologos.cc/logos/bitcoin-cash-bch-logo.png",
  bnb: "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
  busd: "https://cryptologos.cc/logos/binance-usd-busd-logo.png",
  dai: "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png",
  sol: "https://cryptologos.cc/logos/solana-sol-logo.png",
  trx: "https://cryptologos.cc/logos/tron-trx-logo.png",
  dash: "https://cryptologos.cc/logos/dash-dash-logo.png",
  doge: "https://cryptologos.cc/logos/dogecoin-doge-logo.png",
  shib: "https://cryptologos.cc/logos/shiba-inu-shib-logo.png",
  matic: "https://cryptologos.cc/logos/polygon-matic-logo.png",
  ton: "https://cryptologos.cc/logos/toncoin-ton-logo.png",
  verse: "https://s2.coinmarketcap.com/static/img/coins/128x128/22929.png",
  xmr: "https://cryptologos.cc/logos/monero-xmr-logo.png",
};

export const networkTransactionUrls = {
  eth: "https://etherscan.io/tx/%s",
  bsc: "https://bscscan.com/tx/%s",
  arbitrum: "https://arbiscan.io/tx/%s",
  btc: "https://www.blockchain.com/btc/tx/%s",
  ltc: "https://blockchair.com/litecoin/transaction/%s",
  tron: "https://tronscan.org/#/transaction/%s",
  polygon: "https://polygonscan.com/tx/%s",
  avalanche: "https://snowtrace.io/tx/%s",
  ton: "https://tonscan.org/tx/%s",
  sol: "https://solscan.io/tx/%s",
  bch: "https://blockchair.com/bitcoin-cash/transaction/%s",
  dash: "https://blockchair.com/dash/transaction/%s",
  doge: "https://blockchair.com/dogecoin/transaction/%s",
  xmr: "https://xmrchain.net/tx/%s",
  shib: "https://etherscan.io/tx/%s",
};

export const networkMap = {
  eth: {
    title: "Ethereum",
    networks: [
      {
        label: "Ethereum Mainnet (ETH)",
        value: "eth",
        description: "Ethereum Mainnet",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
      {
        label: "Binance Smart Chain (BSC)",
        value: "bsc",
        description: "Binance Smart Chain",
        minWithdrawalUSD: 1.4,
        confirmations: 5.0,
      },
      {
        label: "Arbitrum (ARB)",
        value: "arbitrum",
        description: "Arbitrum Network",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
    ],
  },
  btc: {
    title: "Bitcoin",
    networks: [
      {
        label: "Bitcoin Network (BTC)",
        value: "btc",
        description: "Bitcoin Network",
        minWithdrawalUSD: 2.94,
        confirmations: 1.0,
      },
    ],
  },
  ltc: {
    title: "Litecoin",
    networks: [
      {
        label: "Litecoin Network (LTC)",
        value: "ltc",
        description: "Litecoin Network",
        minWithdrawalUSD: 0.69,
        confirmations: 2.0,
      },
    ],
  },
  usdt: {
    title: "USDT",
    networks: [
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Ethereum",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
      {
        label: "Tron (TRX)",
        value: "tron",
        description: "Tron Network",
        minWithdrawalUSD: 1.0,
        confirmations: 19.0,
      },
      {
        label: "Binance Smart Chain (BSC)",
        value: "bsc",
        description: "Binance Smart Chain",
        minWithdrawalUSD: 1.0,
        confirmations: 5.0,
      },
      {
        label: "Polygon (MATIC)",
        value: "polygon",
        description: "Polygon Network",
        minWithdrawalUSD: 0.5,
        confirmations: 1.0,
      },
      {
        label: "Arbitrum (ARB)",
        value: "arbitrum",
        description: "Arbitrum Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
      {
        label: "Avalanche (AVAX)",
        value: "avalanche",
        description: "Avalanche Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
      {
        label: "Toncoin (TON)",
        value: "ton",
        description: "Toncoin Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
      {
        label: "Solana (SOL)",
        value: "sol",
        description: "Solana Network",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
    ],
  },
  usdc: {
    title: "USDC",
    networks: [
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Ethereum",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
      {
        label: "Binance Smart Chain (BSC)",
        value: "bsc",
        description: "Binance Smart Chain",
        minWithdrawalUSD: 1.0,
        confirmations: 5.0,
      },
      {
        label: "Polygon (MATIC)",
        value: "polygon",
        description: "Polygon Network",
        minWithdrawalUSD: 0.5,
        confirmations: 1.0,
      },
      {
        label: "Arbitrum (ARB)",
        value: "arbitrum",
        description: "Arbitrum Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
      {
        label: "Avalanche (AVAX)",
        value: "avalanche",
        description: "Avalanche Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
    ],
  },
  bch: {
    title: "Bitcoin Cash",
    networks: [
      {
        label: "Bitcoin Cash Network (BCH)",
        value: "bch",
        description: "Bitcoin Cash Network",
        minWithdrawalUSD: 0.03,
        confirmations: 2.0,
      },
    ],
  },
  bnb: {
    title: "Binance Coin",
    networks: [
      {
        label: "Binance Smart Chain (BSC)",
        value: "bsc",
        description: "Binance Smart Chain",
        minWithdrawalUSD: 1.4,
        confirmations: 5.0,
      },
    ],
  },
  trx: {
    title: "Tron",
    networks: [
      {
        label: "Tron Network (TRX)",
        value: "tron",
        description: "Tron Network",
        minWithdrawalUSD: 1.0,
        confirmations: 19.0,
      },
    ],
  },
  dash: {
    title: "Dash",
    networks: [
      {
        label: "Dash Network (DASH)",
        value: "dash",
        description: "Dash Network",
        minWithdrawalUSD: 0.29,
        confirmations: 2.0,
      },
    ],
  },
  doge: {
    title: "Dogecoin",
    networks: [
      {
        label: "Dogecoin Network (DOGE)",
        value: "doge",
        description: "Dogecoin Network",
        minWithdrawalUSD: 0.06,
        confirmations: 1.0,
      },
    ],
  },
  shib: {
    title: "Shiba Inu",
    networks: [
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Shiba Inu Network",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
    ],
  },
  matic: {
    title: "Polygon",
    networks: [
      {
        label: "Polygon (MATIC)",
        value: "polygon",
        description: "Polygon Network",
        minWithdrawalUSD: 0.25,
        confirmations: 1.0,
      },
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Ethereum",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
    ],
  },
  avax: {
    title: "Avalanche",
    networks: [
      {
        label: "Avalanche (AVAX)",
        value: "avalanche",
        description: "Avalanche Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
    ],
  },
  sol: {
    title: "Solana",
    networks: [
      {
        label: "Solana (SOL)",
        value: "sol",
        description: "Solana Network",
        minWithdrawalUSD: 0.002,
        confirmations: 1.0,
      },
    ],
  },
  ton: {
    title: "Toncoin",
    networks: [
      {
        label: "Toncoin (TON)",
        value: "ton",
        description: "Toncoin Network",
        minWithdrawalUSD: 2.0,
        confirmations: 1.0,
      },
    ],
  },
  verse: {
    title: "Verse",
    networks: [
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Ethereum Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
    ],
  },
  xmr: {
    title: "Monero",
    networks: [
      {
        label: "Monero Network (XMR)",
        value: "xmr",
        description: "Monero Network",
        minWithdrawalUSD: 2.0,
        confirmations: 10.0,
      },
    ],
  },
  dai: {
    title: "Dai",
    networks: [
      {
        label: "Binance Smart Chain (BSC)",
        value: "bsc",
        description: "Binance Smart Chain",
        minWithdrawalUSD: 1.0,
        confirmations: 5.0,
      },
      {
        label: "Polygon (MATIC)",
        value: "polygon",
        description: "Polygon Network",
        minWithdrawalUSD: 1.0,
        confirmations: 1.0,
      },
      {
        label: "Ethereum (ETH)",
        value: "eth",
        description: "Ethereum Network",
        minWithdrawalUSD: 10.0,
        confirmations: 1.0,
      },
    ],
  },
};

export const searchMap = {
  support: {
    label: "Support",	
    value: "/support",
    // shortcut: "⌘T",
  },
  
  home: {
    label: "Home",
    value: "/",
  },
  tariffs: {
    label: "Tariffs",
    value: "/tariffs",
  },
  dashboard: {
    label: "Dashboard",
    value: "/protected/dashboard",
  },
  privacy: {
    label: "Privacy Policy",
    value: "/privacy",
  },
  terms: {
    label: "Terms Of Service",
    value: "/terms-of-service",
  },
  gihtub: {
    label: "GitHub",
    value: "https://github.com/rustyspottedcatt",
  },
  twitter: {
    label: "Twitter",
    value: "https://twitter.com/zzen_a",
  },
  login: {
    label: "Login",
    value: "/login",
  },
  signup: {
    label: "Sign Up",
    value: "/signup",
  },
  forgotpassword: {
    label: "Forgot Password",
    value: "/forgot-password",
  },
}